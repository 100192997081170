.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.numberCard {
  padding: 32px;
  margin-bottom: 24px;
  cursor: pointer;
  box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-right: 10px solid blanchedalmond;

  .iconWarp {
    font-size: 54px;
    float: left;
  }

  .content {
    width: 100%;
    padding-left: 78px;

    .title {
      line-height: 16px;
      font-size: 16px;
      margin-bottom: 8px;
      height: 16px;
      .text-overflow();
    }

    .number {
      line-height: 32px;
      font-size: 24px;
      height: 32px;
      .text-overflow();
      margin-bottom: 0;
    }
  }
}

.redCard {
  border-right: 10px solid #d64230;
  background-color: #e46050;
}
@primary-color: #1D3557;@link-color: #E63946;@body-background: #F1FAEE;@component-background: #F1FAEE;@font-family: IRANSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji';