.ant-form-rtl .ant-form-item-label {
    text-align: right !important;
}

.ant-input-number-rtl .ant-input-number-input {
    direction: rtl !important;
}

#root {
    height: 100%;
}

.grecaptcha-badge { 
    visibility: hidden;
}
